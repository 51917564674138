import React from 'react'
import { useAppDispatch, useEffectOnce, useTeardownEffect } from '@/react/hooks'
import { setStatus } from '@/react/features/checkout/statusSlice'
import { ga4_payment_page_view, ga4_add_shipping_info } from '@/services/GA4'
import { DialpadService } from '@/services/DialpadService'

export default function ShowPayment() {
  const dispatch = useAppDispatch()

  useEffectOnce(() => {
    ga4_add_shipping_info()
    ga4_payment_page_view()
    DialpadService.stopRecording()
    dispatch(
      setStatus({
        showPayment: true
      })
    )
  })

  useTeardownEffect(() => {
    DialpadService.startRecording()
    dispatch(
      setStatus({
        showPayment: false
      })
    )
  })

  return <></>
}

import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import {
  Payment,
  PaymentError,
  PaymentKey,
  PaymentValue
} from '@/types/payment'
import {
  FieldValidator,
  DataValidator,
  CheckoutValidator
} from '@/types/validation'
import {
  invalidData,
  doNotValidate,
  validatePaymentMethod,
  validateCreditCardId,
  validatePaymentMethodNonce
} from '@/react/services/checkout_validation'
import rollbar from '@/react/utils/rollbar'

const paymentValidators: FieldValidator<PaymentKey, PaymentValue> = {
  'method': validatePaymentMethod(),
  'credit_card_id': validateCreditCardId(),
  'card_type': doNotValidate(),
  'card_last_4': doNotValidate(),
  'payment_method_nonce': validatePaymentMethodNonce(),
  'purchase_order': doNotValidate(),
  'verified': doNotValidate(),
  'store_in_vault': doNotValidate(),
  'device_data': doNotValidate()
}

export const validatePaymentField: DataValidator<Payment> = (options) => {
  if (!options || invalidData(options.data) || isEmpty(options.data)) {
    return []
  }
  const { required } = options
  const fieldName = Object.keys(options.data)[0] as PaymentKey

  if (fieldName in paymentValidators) {
    const value = options.data[fieldName]
    return paymentValidators[fieldName]({ value, required })
  } else {
    rollbar.error('no validations available for payment field: ', fieldName)
    return []
  }
}

const validateCC = (
  payment: Payment
): {
  error?: string
  valid: boolean
} => {
  const hasId =
    validatePaymentField({
      data: { credit_card_id: payment.credit_card_id || '' },
      required: true
    }).length === 0
  const hasNonce =
    validatePaymentField({
      data: { payment_method_nonce: payment.payment_method_nonce || '' },
      required: true
    }).length === 0

  return hasId || hasNonce
    ? {
        valid: true
      }
    : {
        error: 'credit card validation failure',
        valid: false
      }
}

export const validatePayment: CheckoutValidator<Payment, PaymentError> = (
  options
) => {
  const errors = {} as PaymentError
  if (!options || invalidData(options.data)) {
    return { errors, valid: false }
  }
  const payment = options.data as Payment
  const paymentMethodErrors = validatePaymentField({
    data: { method: payment.method || '' }
  })

  if (paymentMethodErrors.length > 0) {
    return {
      errors: {
        method: paymentMethodErrors.join(', ')
      },
      valid: false
    }
  }

  if (includes(['paypal', 'applepay'], payment.method)) {
    return { errors, valid: true }
  }

  if (payment.method === 'cc') {
    const ccValidation = validateCC(payment)
    return {
      errors: {
        cc: ccValidation.error
      },
      valid: ccValidation.valid
    }
  }

  if (payment.method === 'purchase_order') {
    throw Error('use validatePurchaseOrder instead of validatePayment')
  }

  return { errors, valid: false }
}

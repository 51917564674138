import rollbar from "@/react/utils/rollbar"

export class DialpadService {
  static startRecording(): void {
    this.sendMessage('phone.recording.start')
  }

  static stopRecording(): void {
    this.sendMessage('phone.recording.stop')
  }

  private static sendMessage(event: string, data: any = null): void {
    try {
      if (typeof window !== 'undefined' && window.parent !== window) {
        window.parent.postMessage({ event, data }, '*')
      }
    } catch (error) {
      rollbar.error('Dialpad call masking error', error)
    }
  }
}
